@import "src/themes/mojito/styles/index.scss";
.Links {
  &__input-wrapper {
    border-top: 2px solid getvar($colors, "neutral", "300");
    padding: space(1) 0px;
  }

  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    button {
      margin: 0 !important;
    }
  }

  &__error {
    color: getvar($colors, "error", "600");
  }
}
