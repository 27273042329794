@import "src/themes/mojito/styles/index.scss";
.slider {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__label {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  &__input {
    width: 100%;
  }
}
