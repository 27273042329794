@import "src/themes/mojito/styles/index.scss";
.image-cropper {
  width: 100%;
  height: auto;
  max-height: 40rem;

  &__cropper {
    position: relative;
    width: 100%;
    min-height: 250px;
    height: 50%;
  }

  &__settings {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    text-align: left;

    &__sliders {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      box-sizing: border-box;

      &--zoom {
        margin-bottom: 1rem;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin: 1rem;

      p {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
      }

      &--rotate {
        display: flex;
        gap: 1rem;
      }

      &--flip {
        display: flex;
        gap: 1rem;
      }
    }
  }
}

.button--image-cropper {
  margin: 1rem auto;
  min-width: 60%;
}
